import {
  Navigation,
  UserType,
  VehicleType,
  PointType,
  DocumentStatus,
  NDN,
  NRN,
  TabIndex,
} from './enum';

import {
  startWork, startWork_, workHere, hoursWeekCompany1, hoursWeekCompany2, hoursWeekCompany3
} from './settings'

export const regexemail =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export interface CodeRegister {
  id: number
  value: string
}

export interface Averages {
  element: JSX.Element[]
  variable: string[]
  type: Array<'Mediana'|'Povprečje'>
  means: any
}

export type Statistics = 'Mediana'|'Povprečje'

interface Message {
  useruuid: string;
  orderuuid: string;
  departureDate: string;
  intent: string;
  projectuuid: string;
}

export interface Expense {
  uuid: string;
  value: string;
}

export interface Files {
  name: string;
  size: number;
  type: string;
  value: string;
  // encoded: Uint8Array;
  binaryValue: ArrayBuffer;
  filename: string;
}

export interface Attachment {
  name: string;
  description: string;
  files: Files[];
}

export interface AttachmentWithoutFiles {
  name: string;
  description: string;
}


export interface Subsistence {
  uuid: string;
  breakfast: boolean;
  lunch: boolean;
  dinner: boolean;
}

export interface DocumentData {
  sequential?: number
  status: DocumentStatus;
  departure?: string;
  arrival?: string;
  companionsPlanned?: string;
  vehicleTypePlanned: VehicleType;
  companions?: string;
  vehicletype?: VehicleType;
  vehicleuuidPlanned: string;
  descriptionPlanned?: string;
  uuid: string;
  email: string;
  companyuuid?: string;
  useruuid: string;
  userUuid: string;
  departureDate?: Date;
  arrivalDate?: Date;
  departureDatePlanned: Date;
  arrivalDatePlanned: Date;

  departureHour?: string;
  arrivalHour?: string;
  departureMinute?: string;
  arrivalMinute?: string;
  departureHourPlanned: string;
  arrivalHourPlanned: string;
  departureMinutePlanned: string;
  arrivalMinutePlanned: string;
  vehicleuuid?: string;
  mileageuuid?: string;
  name?: string;
  departuremileage?: string;
  arrivalmileage?: string;
  mileage?: string;
  mileageGoogle?: string;
  description?: string;
  projectuuid?: string;
  projectuuidPlanned?: string;
  intent?: string;
  intentPlanned?: string;
  advance?: string;
  advancedate?: Date;
  // customeruuid?: string;
  // customeruuidPlanned?: string;
  companyuuidPlanned?: string;
  path?: string;
  orderuuid?: string;
  orderuuidPlanned?: string;
  attachment?: Attachment;
  attachments?: Attachment[];
  Attachments?: Attachment[];
  subsistence?: Subsistence[];
  expenses?: Expense[];
  pointuuids: string[];
  Pointuuids: string[];
}

interface KedataUserWorking {
  companyuuid: string
  companyuuid_: string
  hours: string
}

export interface Kedata {
  uuid: string
  address: string
  addresstemp: string
  birthdate: Date
  birthdate_: string
  birthdate__: number
  // companyuuid: string
  userWorking: KedataUserWorking[]
  education: number
  education_: string
  email: string
  email2: string
  title: number
  title_: string
  emso: string
  endwork: Date
  endwork_: string
  firstname: string
  lastname: string
  medical: Date
  medical_: string
  medical__: string
  medical___: boolean
  permitnumber: string
  additionalpermits: string
  registered: string
  section: number
  section_: string
  sex: number
  specialpermits: number
  specialpermits_: string
  startwork: Date
  startwork_: string
  taxnumber: string
  telephone: string
  telephone2: string
  usertype: UserType
  workall1: string
  workall2: string
  workall3: string
  workhere1: string
  workhere2: string
  workhere3: string

  working: number
  working_: string
  workingbefore: string
  workingbefore1: string
  workingbefore2: string
  workingbefore3: string
  workingstatus: number
  workposition: number
  workposition_: string
  worksecurity: Date
  worksecurity_: string
  worksecurity__: string
  worksecurity___: boolean
}
/*
interface KedataAllUserWorking {
  'Odstotek ur': number
  'Podjetje': string
  'Št. ur': string
}
*/

export interface KedataAll {
  'Stalni naslov'?: string
  'Začasni naslov'?: string
  'Datum rojstva'?: string
  'Datum rojstva_'?: string
  'Starost'?: number
  'Izobrazba'?: string
  'Elektronski naslov'?: string
  'EMŠO'?: string
  'Naziv'?: string
  'Zaključek dela'?: string
  'Zaključek dela_'?: string
  'Ime'?: string
  'Priimek'?: string
  'Veljavnost zdravniškega pregleda'?: string
  'Veljavnost zdravniškega pregleda_'?: string
  'Veljavnost dela na višini'?: string
  'Veljavnost dela na višini_'?: string
  'Št. pooblastila'?: string
  'Dodatna dovoljenja'?: string
  'Oddelek'?: string
  'Spol'?: string
  'Posebna pooblastila'?: string
  [startWork]?: string
  [startWork_]?: string
  'Davčna št.'?: string
  'Telefonska št.'?: string
  // 'Podjetje zaposlitve'?: KedataAllUserWorking[]
  'Uporabniški nivo'?: string
  'Vrsta zaposlitve'?: string
  'Status zaposlitve'?: string
  'Delovna doba - predhodna'?: string
  [workHere]?: string
  'Delovna doba - skupna'?: string
  'Delovno mesto'?: string
  'Veljavnost izpita iz varstva pri delu'?: string
  'Veljavnost izpita iz varstva pri delu_'?: string
  'Izpit iz varstva pri delu'?: string
  'Skupno št. ur na teden'?: number
  [hoursWeekCompany1]?: number
  [hoursWeekCompany2]?: number
  [hoursWeekCompany3]?: number
}

export interface AnalyticsCount {
  confirmed?: number
  updated?: number
  updatedPlus?: number
  finalized?: number
  all?: number
}

export interface State {
  fCountry: string
  showMineBoss: boolean
  showJustMine: boolean
  showProblematic: boolean
  vehicleuuid: string
  mean: boolean[]
  median: boolean[]
  groupColor: boolean
  groupNumber: boolean
  groupEmpty: boolean
  group?: number
  groups?: string[]
  groupsColors: string[]
  groupsColorsDefault: string[]
  f: string[][][]
  variablesNull: boolean[]
  variables: number[]
  Keys?: boolean[]

  keys?: string[]
  keysSearch?: string[]
  keysType?: string[]
  variablesSelected: string[][]

  variablesOrderType: boolean[]
  variablesOrder: number[]

  kedataAll?: any[][]
  kedataAll0?: any[]
  kedatall?: KedataAll[]
  kedataolduserworking?: number
  kedata?: Kedata
  kedataold?: Kedata
  ke?: string,
  loader: boolean
  orientation: 0 | 90 | 180 | 270;
  scale: number;
  randomCharacters: number;
  FVehicleuuid: string;
  fVehicleuuid: string;

  FProjectuuid: string;
  fProjectuuid: string;

  FUseruuid: string;
  fUseruuid: string;
  FUserUuid: string;
  fUserUuid: string;

  FOrderuuid: string,
  fOrderuuid: string,

  fVehiclePlate: string;
  fCompanyuuid: string;
  FCompanyuuid: string;
  fCompanyVehicle: boolean;
  popup: boolean;
  fDeparture: Date;
  fArrival?: Date;
  fdeparture: Date;
  farrival: Date;
  fDep: Date;
  fArr: Date;
  fStatus: string;

  addingPoints: boolean;
  vehicleOccupancy: boolean;
  viewingCurrentMonth: boolean;
  viewingCurrentMonthData: DocumentData[];
  pageNumber: number;
  numPages: number;
  Country: string;
  ExposedPoint: string;
  // continentEurope: boolean
  attachment: number;
  src?;
  enlargeImage: boolean;
  showImage?: string;
  showPdf?: string;
  lastTabIndex: TabIndex;
  tabIndex: TabIndex;
  fCompany: string;
  leaders: string[];
  Leaders: string[];
  messages: Message[];
  platform: 'mobile' | 'desktop';
  ndn: NDN;
  nrn: NRN;
  document?: DocumentData;
  navigation: Navigation;
  pSize0: number;
  pNum0: number;
  pSize1: number;
  pNum1: number;
  pSize2: number;
  pNum2: number;
  pSize3: number;
  pNum3: number;
  pSize4: number;
  pNum4: number;
  pSize5: number;
  pNum5: number;
  pSize6: number;
  pNum6: number;
  pSize7: number;
  pNum7: number;
  pSize8: number;
  pNum8: number;
  pSize9: number;
  pNum9: number;
  pSize10: number;
  pNum10: number;
  pSize11: number;
  pNum11: number;
  pSize12: number;
  pNum12: number;
  pSize13: number;
  pNum13: number;
  pSize14: number;
  pNum14: number;
  pSize15: number;
  pNum15: number;

  SectionCompanyuuid: string;
  Section: string;
  SectionLeaderuuid: string;
  CompanyLeaderuuid: string;
  Company: string;
  // Companyuuid: string;
  CompanyPointuuid: string;
  Point: string;
  PointAddress: string;
  PointShortName: string;
  PointUseruuid: string;
  Customer: string;
  CustomerAddress: string;
  CustomerPointuuid: string;
  Project: string;
  ProjectCode: string;
  Vehicle: string;
  VehicleMy: string;
  VehicleType: VehicleType;
  VehicleUseruuid: string;
  VehiclePlate: string;
  VehiclePlateMy: string;

  Subsistence: string;
  SubsistenceCountry: string;
  SubsistenceValue: string;
  SubsistenceBreakfast: string;
  SubsistenceLunch: string;
  SubsistenceDinner: string;
  Mileage: string;
  Expense: string;
  MileageValue: string;
  userData?: UserData[];
  companies?: CompanyData[];
  sections?: SectionData[];
  points?: PointData[];
  // customers?: CustomerData[];
  documents?: DocumentData[];
  Documents?: number[]
  analyticsData?: DocumentData[];
  analyticsDataCount: AnalyticsCount;
  analyticsUser?: DocumentData[];
  analyticsUserCount: AnalyticsCount;

  projects?: ProjectData[];
  reservations?: ReservationData[];
  reservationsDay: ReservationData[];
  expense?: ExpenseData[];
  countries?: CountryData[];
  pointExposed?: PointExposedData[];
  subsistence?: SubsistenceData[];
  vehicles?: VehicleData[];
  mileage?: MileageData[];
  companyuuid: string;
  firstname: string;
  lastname: string;
  email: string;
  telephone: string;
  password1: string;
  password2: string;
  showPassword: boolean;
  ShowPassword: boolean;
  Firstname: string;
  Lastname: string;
  Telephone: string;
  Email: string;
  Password1: string;
  Password2: string;
  Level: UserType;
  fLevel: UserType;
  fVehicleType: VehicleType;
  fPointType: PointType;
  showRegistration: boolean;
  showRegistrationCountry: boolean;
  showRegistrationSection: boolean;
  showRegistrationPoint: boolean;
  showRegistrationCompany: boolean;
  showRegistrationCustomer: boolean;
  showRegistrationProject: boolean;
  showRegistrationVehicle: boolean;
  showRegistrationSubsistence: boolean;
  showRegistrationMileage: boolean;
  showRegistrationExpense: boolean;
  showRegistrationExposedPoint: boolean;
  PointShortMy: string
  PointNameMy: string
  PointAddressMy: string
  message?: string;
}

export interface Props {
  userData: UserData;
  router: (register: boolean, login: boolean, userData?: UserData) => void;
  changeUserData: (variable: userdata, value: string) => void;
  changeUserDataAdmin: (value: number) => void;
}

export interface InsertDocument {
  email: string
  // whois?: string;
  uuid: string;
  useruuid: string;
  departure: string;
  arrival: string;
  departureDatePlanned?: Date;
  arrivalDatePlanned?: Date;
  departureHourPlanned?: string;
  arrivalHourPlanned?: string;
  departureMinutePlanned?: string;
  arrivalMinutePlanned?: string;

  vehicleTypePlanned?: VehicleType;
  vehicleuuidPlanned?: string;
  projectuuidPlanned?: string;
  // customeruuidPlanned?: string;
  companyuuidPlanned?: string;
  intentPlanned?: string;
  descriptionPlanned?: string;
  companionsPlanned?: string;

  orderuuidPlanned?: string;

  pointuuids: string[];
  path?: string;
  mileageGoogle?: string;
  status: DocumentStatus;
}

export interface UpdateDocument extends InsertDocument {
  departureDate: Date;
  arrivalDate: Date;
  departureHour: string;
  departureMinute: string;
  arrivalHour: string;
  arrivalMinute: string;
  vehicletype: VehicleType;
  vehicleuuid: string;
  projectuuid: string;
  customeruuid: string;
  intent: string;
  description: string;
  companions: string;
  orderuuid: string;
  mileage: string;

  attachments: Attachment[];
  subsistence: Subsistence[];
  expenses: Expense[];
  mileageuuid: string;
  companyuuid: string;
  advance: string;
  advancedate: string;
  departuremileage: string;
  arrivalmileage: string;
}

export interface Filter {
  id: string;
  value: string;
}

export interface LoginData {
  email: string;
  password: string;
  Email?: string
}

export interface LoginDataSuccess {
  uuid: string;
  usertype: UserType;
  firstname?: string;
  lastname?: string;
  email?: string;
}

export interface PointData {
  uuid: string;
  name: string;
  address: string;
  shortname: string;
  useruuid: string;
  active: boolean;
}

export interface ProjectData {
  uuid: string;
  name: string;
  code: string;
  active: boolean;
}

export interface ReservationData {
  uuid: string;
  useruuid: string;
  vehicleuuid: string;
  departure: string;
  arrival: string;
  path: string
}

export interface SubsistenceData {
  uuid: string;
  name: string;
  country: string;
  value: string;
  breakfast: string;
  lunch: string;
  dinner: string;
  active: boolean;
}

export interface MileageData {
  uuid: string;
  name: string;
  value: string;
  active: boolean;
}

export interface ExpenseData {
  uuid: string;
  name: string;
  active: boolean;
}

export interface CountryData {
  uuid: string;
  name: string;
  ordinal: number;
}

export interface PointExposedData {
  uuid: string;
  name: string;
  ordinal: number;
}

export interface CompanyData {
  uuid: string;
  name: string;
  pointuuid: string;
  leaderuuid: string;
  n: string
  abbr?: string
}

export interface SectionData {
  uuid: string;
  name: string;
  companyuuid: string;
  leaderuuid: string;
}

export interface Params {
  token: string;
}

export interface FilterDocumentsParams extends Params {
  departure: string;
  arrival: string;
  companyVehicle?: boolean;
  companyuuid?: string;
  useruuid?: string;
  orderuuid?: string;
  ownUseruuid?: string;
  vehicleuuid?: string;
  projectuuid?: string;
  vehicleplate?: string;
  status?: string
  analyticsUser?: boolean
}

export interface OpenDocumentParams extends Params {
  type: string;
  uuid: string;
}

export interface VehicleOccupancyParams extends Params {
  departure: string;
  arrival: string;
}
/*
export interface CustomerData {
  uuid: string;
  name: string;
  address: string;
  pointuuid: string;
}
*/
export interface UserData {
  email: string;
  firstname?: string;
  lastname?: string;
  telephone?: string;
  uuid?: string;
  companyuuid?: string;
  registered?: string;
  usertype?: UserType;
  active?: boolean
  token?: string;
}

export type userdata = 'firstname' | 'lastname' | 'email' | 'telephone';

export interface SetAdminData {
  token: string;
  uuid: string;
  usertype: UserType;
}

export interface RegisterData {
  password1: string;
  password2: string;
  userData: UserData;
  token?: string;
}

export interface VehicleData {
  uuid: string;
  type: VehicleType;
  useruuid: string;
  plate: string;
  name: string;
  active: boolean;
}
